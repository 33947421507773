import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loadning: false,
    user: null,
    error: null,
  },
  reducers: {
    login: (state, action) => {
      state.loadning = false
      state.user = action.payload
      state.error = null
    },
    logout: (state) => {
      state.loadning = false
      state.user = null
      state.error = null
    },
  },
})

export const { login, logout } = userSlice.actions
export const selectUser = (state) => state.user.user
export default userSlice.reducer
