import React, { Component, Suspense, useState } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from './features/userSlice'
import './scss/style.scss'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const user = localStorage.getItem('user')
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            exact
            path="/Login"
            name="Login Page"
            // element={user ? <DefaultLayout /> : <Login />}
            element={user ? <DefaultLayout /> : <Login />}
          />
          <Route
            path="*"
            name="Home"
            element={user ? <DefaultLayout /> : <Navigate replace to="Login" />}
          />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
